import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ContactsIcon from '@mui/icons-material/Contacts';

const AllRatificatorsAsignedFilter = ({ filter, setFilter }) => (
  <Tooltip title="Todos los ratificadores">
    <IconButton onClick={() => setFilter(!filter)}>
      <ContactsIcon color={!filter ? 'primary' : 'text.primary'} />
    </IconButton>
  </Tooltip>
);

AllRatificatorsAsignedFilter.propTypes = {
  filter: PropTypes.bool.isRequired,
  setFilter: PropTypes.func.isRequired,
};

export default AllRatificatorsAsignedFilter;
