import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import React, { useCallback, useState } from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import {
  COLUMNS,
  INITIAL_ORDER_BY,
  NO_ROWS_MESSAGE,
  SEARCH_PLACEHOLDER,
  TITLE,
} from '../../constants/ratification-documents-review';
import { GET_PENDING_DOCUMENTS_REVIEW_INVOICES } from '../../graphql/ratification/queries';
import {
  isRowSelectable,
  renderCell,
} from '../../helpers/ratification-documents-review';
import RatificationDocumentsReviewActions from './ratification-documents-review/RatificationDocumentsReviewActions';

const RatificationDocumentsReview = () => {
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);
  const [resolutions, setResolutions] = useState({});
  const handleStatusChange = useCallback((row, docType, status) => {
    setResolutions((prev) => ({
      ...prev,
      [row.offer.id]: { ...prev[row.offer.id], [docType]: status },
    }));
    setSelectedInvoiceIds((prev) => {
      if (!prev.includes(row.id)) {
        return [...prev, row.id];
      }
      return [...prev];
    });
  }, []);
  const clear = useCallback(() => {
    setSelectedInvoiceIds([]);
    setResolutions({});
  }, []);
  const country = useGetCountryFromUrl();
  const columns = [
    ...COLUMNS,
    {
      field: 'offer_ReviewDocumentsManager_HesReviewState',
      headerName: 'HES',
      align: 'center',
      maxWidth: 100,
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row.offer.reviewDocumentsManager.hesReviewState,
      renderCell: ({ row }) => renderCell({ row }, 'hesReviewState', handleStatusChange, resolutions),
      order: 5,
    },
    {
      field: 'offer_ReviewDocumentsManager_OcReviewState',
      headerName: 'OC',
      align: 'center',
      maxWidth: 100,
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row.offer.reviewDocumentsManager.ocReviewState,
      renderCell: ({ row }) => renderCell({ row }, 'ocReviewState', handleStatusChange, resolutions),
      order: 6,
    },
    {
      field: 'offer_ReviewDocumentsManager_PortalScreenshotReviewState',
      headerName: 'Print Portal',
      align: 'center',
      maxWidth: 100,
      sortable: false,
      filterable: false,
      valueGetter: ({ row }) => row.offer.reviewDocumentsManager.portalScreenshotReviewState,
      renderCell: ({ row }) => renderCell(
        { row },
        'portalScreenshotReviewState',
        handleStatusChange,
        resolutions,
      ),
      order: 7,
    },
  ].sort((a, b) => a.order - b.order);

  return (
    <FingoMainView
      id="ordering-operations"
      query={GET_PENDING_DOCUMENTS_REVIEW_INVOICES}
      queryCustomVariables={{
        status: ['PendingDocumentsReview'],
        dateIssued_Gte: formatYearMonthDay(dayjs().subtract(100, 'days')),
        dteType_CountryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        actions: RatificationDocumentsReviewActions,
        table: FingoDataGrid,
      }}
      slotProps={{
        header: {
          viewTitle: TITLE,
          finder: { searchPlaceHolder: SEARCH_PLACEHOLDER },
        },
        actions: {
          resolutions,
          clear,
          selectedInvoiceIds,
        },
        table: {
          columns,
          checkboxSelection: true,
          disableSelectionOnClick: true,
          onSelectionModelChange: setSelectedInvoiceIds,
          isRowSelectable,
          selectionModel: selectedInvoiceIds,
          density: 'compact',
          noRowsMessage: NO_ROWS_MESSAGE,
          initialOrderBy: INITIAL_ORDER_BY,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
      }}
    />
  );
};

export default RatificationDocumentsReview;
