import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { PaperHeader } from '@fingo/lib/components/headers';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import {
  useInvoiceLucilaCustomColumns,
  useInvoicePreColumns,
} from '@fingo/lib/constants';
import { INVOICES_RATIFICATION } from '@fingo/lib/graphql';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import { formatYearMonthDay } from '@fingo/lib/helpers';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useGetMasterEntity from '@fingo/lib/hooks/useGetMasterEntity';
import { useGetBackOfficeUser } from '@fingo/lib/hooks';
import useRatificationColumns from '../../../../columns/useRatificationColumns';
import RatificationActions from './ratificationActions/RatificationActions';

const NoRows = () => <Typography>No hay facturas que ratificar</Typography>;

const siiFilterOptionsMap = {
  PRIORITY: {
    All: 'Estado',
    'Reception acknowledged': 'Con acuse de recibo',
    Rejected: 'Rechazadas',
    Valid: 'Sin acciones en el Sii',
  },
  NON_PRIORITY: {
    All: 'Estado',
    Rejected: 'Rechazadas',
    Valid: 'Sin acciones en el Sii',
  },
};

const AbstractRatificationPanel = ({
  includeHeaders,
  ratificationStatus,
  panelId,
  viewTitle,
}) => {
  const currentUser = useGetBackOfficeUser();
  const { debtorId } = useParams();
  const masterEntity = useGetMasterEntity(debtorId);
  const [selectionModel, setSelectionModel] = useState([]);
  const [siiFilter, setSiiFilter] = useState('All');
  const [commercialExecutiveFilter, setCommercialExecutiveFilter] = useState(
    ratificationStatus === 'PRIORITY' ? false : null,
  );
  const [noRatificationActionsFilter, setNoRatificationActionsFilter] = useState(false);
  const [filterByRatificatorAsigned, setFilterByRatificatorAssigned] = useState(false);
  const ratificationColumns = useRatificationColumns();
  const columns = useInvoicePreColumns([
    ...ratificationColumns,
    ...useInvoiceLucilaCustomColumns(false, 'company'),
  ]);
  const country = useGetCountryFromUrl();
  const customQueryVariables = useMemo(
    () => ({
      ratificationStatus,
      dateIssued_Gte: formatYearMonthDay(dayjs().subtract(6, 'months')),
      dteType_CountryId: country?.id,
      siiStatus: siiFilter !== 'All' ? siiFilter : null,
      ratificationmanager_Actions_Isnull: !noRatificationActionsFilter
        ? null
        : true,
      collectionManagerRelation_Actions_Isnull: true,
      filterByRatificator: filterByRatificatorAsigned,
      receiverId: debtorId,
      distinct: true,
      ...(ratificationStatus === 'PRIORITY' && {
        ratificationmanager_RatificationPriority_Value_In:
          currentUser?.executive?.isCommercialExecutive
          || commercialExecutiveFilter
            ? [3]
            : [1, 2],
      }),
    }),
    [
      currentUser,
      country,
      ratificationStatus,
      siiFilter,
      noRatificationActionsFilter,
      filterByRatificatorAsigned,
      commercialExecutiveFilter,
    ],
  );

  const siiOptions = siiFilterOptionsMap[ratificationStatus] || {};

  return (
    <FingoMainView
      id={panelId}
      query={INVOICES_RATIFICATION}
      queryCustomVariables={customQueryVariables}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: RatificationActions,
      }}
      slotProps={{
        header: {
          viewTitle: `${viewTitle} ${
            masterEntity?.name || masterEntity?.rut || ''
          }`,
          finder: {
            searchPlaceHolder: 'Buscar',
          },
        },
        table: {
          columns,
          includeHeaders,
          noRowsMessage: NoRows,
          initialOrderBy: 'ratificationmanager_RatificationPriority_Value',
          checkboxSelection: true,
          selectionModel,
          onSelectionModelChange: setSelectionModel,
          keepNonExistentRowsSelected: true,
          rowsPerPageOptions: [10, 25, 50, 100],
        },
        actions: {
          selectedInvoiceIds: selectionModel,
          setSelectedInvoices: setSelectionModel,
          exportVariables: customQueryVariables,
          siiFilter,
          setSiiFilter,
          siiOptions,
          noRatificationActionsFilter,
          setNoRatificationActionsFilter,
          filterByRatificatorAsigned,
          setFilterByRatificatorAssigned,
          commercialExecutiveFilter,
          setCommercialExecutiveFilter,
        },
      }}
    />
  );
};

AbstractRatificationPanel.propTypes = {
  includeHeaders: PropTypes.arrayOf(PropTypes.string).isRequired,
  ratificationStatus: PropTypes.oneOf(['PRIORITY', 'NON_PRIORITY']).isRequired,
  panelId: PropTypes.string.isRequired,
  viewTitle: PropTypes.string.isRequired,
};

export default AbstractRatificationPanel;
