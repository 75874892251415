import React from 'react';
import dayjs from '@fingo/lib/config/dayjs';
import Typography from '@mui/material/Typography';
import { formatDayMonthYearSlash, formatMoney } from '@fingo/lib/helpers';
import { MasterEntityNameCell } from '@fingo/lib/components/cells';

export default [
  {
    field: 'folio',
    headerName: 'Folio',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">{row.folio}</Typography>
    ),
    width: 80,
  },
  {
    field: 'receiver',
    headerName: 'Deudor',
    sortable: true,
    renderCell: ({ row }) => (
      <MasterEntityNameCell masterEntity={row.receiver} type="receiver" />
    ),
    width: 80,
  },
  {
    field: 'dateIssued',
    headerName: 'Fecha de emisión',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">
        {formatDayMonthYearSlash(dayjs(row.dateIssued))}
      </Typography>
    ),
    width: 40,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto factura',
    sortable: true,
    renderCell: ({ row }) => (
      <Typography alignSelf="start">
        ${formatMoney(row.amountWithIva)}
      </Typography>
    ),
    width: 80,
  },
];
